import React, { useRef, useEffect, useState, useMemo } from 'react';
import MapboxDraw from '@mapbox/mapbox-gl-draw';
import styled from 'styled-components';
import mapboxGl from 'mapbox-gl';
import Legend from './Legend';
import Floors from './Floors';
import Controls from './Controls';
import Contacts from './Contacts';
import { RotateMode, RotateModeStyles } from '../modes/rotate-mode';
import { SelectMode } from '../modes/select-mode';
import { getPointFromCenter, polygonToPointCollection } from '../util/geometry';
import {  generatePlans } from '../util';
import 'overlayscrollbars/overlayscrollbars.css';
import 'mapbox-gl/dist/mapbox-gl.css';
import '@mapbox/mapbox-gl-draw/dist/mapbox-gl-draw.css';

// eslint-disable-next-line import/no-webpack-loader-syntax
mapboxGl.workerClass = require("worker-loader!mapbox-gl/dist/mapbox-gl-csp-worker").default;
mapboxGl.accessToken = 'pk.eyJ1IjoidG9wb2wiLCJhIjoiY2lndjM4eDNxMDA0M3Zma3JiOGRmcGNyOSJ9.tPBrXFyMAspRCTjyVKmx8A';

const App = () => {
  const [map, setMap] = useState(null);
  const [floor, setFloor] = useState(0);
  const [hour, setHour] = useState(0);
  const [floors, setFloors] = useState(null);
  const [loading, setLoading] = useState(true);
  const duration = useMemo(() => 300, []);
  const mapRef = useRef(null);
  const initFloorRef = useRef(null);
  const updateCirclesRef = useRef(null);
  const requestRef = useRef(0);
  const startTimeRef = useRef(0);
  const floorRef = useRef(floor);

  const onFloorChange = (floor) => {
    initFloorRef.current?.(floor, hour);
    setFloor(floor);
    floorRef.current = floor;
  }

  const onHourChange = (hour) => {
    updateCirclesRef.current?.(hour);
    setHour(hour);
  }

  useEffect(() => {

    let map = null;

    (async () => {
      try {
        const { mapParams, floors: FLOORS } = await generatePlans();

        map = new mapboxGl.Map({
          container: mapRef.current || '',
          center: mapParams.centroid,
          zoom: 16.8,
          minZoom: 16,
          maxZoom: 22,
          style: { version: 8, sources: {}, layers: [], glyphs: "mapbox://fonts/mapbox/{fontstack}/{range}.pbf" },
          antialias: true,
        });

        const draw = new MapboxDraw({
          displayControlsDefault: false,
          defaultMode: 'simple_select',
          modes: {
            rotate: RotateMode,
            simple_select: SelectMode,
          },
          styles: [ ...RotateModeStyles ]        
        });

        window.map = map;
        window.draw = draw;

        map.dragRotate.disable();
        map.touchZoomRotate.disableRotation();
        map.addControl(draw);

        const initFloor = (floor, hour) => {
          setTimeout(() => {
            if (map.getLayer('floor')) map.removeLayer('floor');
            if (map.getLayer('floor-outline')) map.removeLayer('floor-outline');
            if (map.getLayer('room-outline')) map.removeLayer('room-outline');
            if (map.getLayer('room-labels')) map.removeLayer('room-labels');
            if (map.getLayer('interrior')) map.removeLayer('interrior');
            if (map.getLayer('point-circles')) map.removeLayer('point-circles');
            if (map.getLayer('point-circles-1')) map.removeLayer('point-circles-1');
            if (map.getLayer('point-labels')) map.removeLayer('point-labels');
            if (map.getSource('room')) map.removeSource('room');
            if (map.getSource('interrior')) map.removeSource('interrior');
            if (map.getSource('points')) map.removeSource('points');
            if (map.getSource('labels')) map.removeSource('labels');
            if (map.getSource('room-labels')) map.removeSource('room-labels');
            draw.deleteAll();

            const firstDrawLayer = map.getStyle().layers.find(l => l.id.startsWith('gl-'))?.id;

            map.addSource('room', {
              type: 'geojson',
              data: FLOORS[floor].room,
            });

            map.addSource('interrior', {
              type: 'geojson',
              data: FLOORS[floor].interriorObjects,
            });

            map.addSource('points', {
              type: 'geojson',
              data: {
                ...FLOORS[floor].points,
                features: FLOORS[floor].points.features.map(p => {
                  p.properties.value = p.properties.values[hour] ?? p.properties.values[p.properties.values.length - 1];
                  return p;
                }),
              },
            });

            map.addSource('labels', {
              type: 'geojson',
              data: {
                ...FLOORS[floor].labels,
                features: FLOORS[floor].labels.features.map(p => {
                  p.properties.value = p.properties.values[hour] ?? p.properties.values[p.properties.values.length - 1];
                  return p;
                }),
              },
            });

            map.addSource('room-labels', {
              type: 'geojson',
              data: polygonToPointCollection(FLOORS[floor].room)
            })

            map.addLayer({
              id: 'floor',
              type: 'fill',
              source: 'room',
              filter: ['==', ['get', 'type_name'], 'border'],
              paint: {
                'fill-color': '#171410',
                'fill-outline-color': 'rgba(0,0,0,0)',
              }
            }, firstDrawLayer);

            map.addLayer({
              id: 'room-outline',
              type: 'line',
              source: 'room',
              filter: ['!=', ['get', 'type_name'], 'border'],
              paint: {
                'line-color': '#403d36',
                'line-width': 2,
              }
            }, firstDrawLayer);

            map.addLayer({
              id: 'floor-outline',
              type: 'line',
              source: 'room',
              filter: ['==', ['get', 'type_name'], 'border'],
              paint: {
                'line-color': '#918d85',
                'line-width': 4,
              }
            }, firstDrawLayer);

            map.addLayer({
              id: 'room-labels',
              type: 'symbol',
              source: 'room-labels',
              filter: ['all', 
                ['!=', ['get', 'type_name'], 'border'],
                ['!=', ['get', 'type_name'], 'casino'],
                ['!=', ['get', 'type_name'], 'stairs'],
              ],
              layout: {
                'text-field': ['get', 'type_caption'],
                'text-size': ['interpolate', ['linear'], ['zoom'], 18, 10, 22, 70],
                'text-font': ['Open Sans Bold'],
                'text-transform': 'uppercase',
              },
              paint: {
                'text-color': '#545047',
              }
            });

            map.addLayer({
              id: 'interrior',
              type: 'fill',
              source: 'interrior',
              paint: {
                'fill-color': '#D8C392',
                'fill-outline-color': '#D8C392',
              }
            }, firstDrawLayer);

            map.addLayer({
              id: 'point-circles',
              type: 'circle',
              source: 'points',
              minzoom: 20,
              layout: { 'visibility': 'none' },
              paint: {
                'circle-stroke-width': 3,
                'circle-stroke-color': '#fff',
                'circle-opacity': 0.8,
                'circle-color': [
                  'interpolate', ['linear'], ['get', 'value'],
                  1000, '#D8C392',
                  200000, '#B7591A',
                ],
                "circle-radius": [
                  "interpolate", ["linear"], ["get", "value"],
                  1000, 20,
                  200000, 40,
                ],
              }
            });

            map.addLayer({
              id: 'point-circles-1',
              type: 'circle',
              source: 'points',
              maxzoom: 20,
              layout: { 'visibility': 'none' },
              paint: {
                'circle-stroke-width': 1,
                'circle-stroke-color': '#fff',
                'circle-opacity': 0.8,
                'circle-color': [
                  'interpolate', ['linear'], ['get', 'value'],
                  1000, '#D8C392',
                  200000, '#B7591A',
                ],
                "circle-radius": [
                  'interpolate', ['linear'], ['zoom'],
                  17, 4,
                  20, 15,
                ],
              }
            });

            map.addLayer({
              id: 'point-labels',
              type: 'symbol',
              source: 'labels',
              minzoom: 20,
              layout: {
                'text-field': ['concat', '$', ['to-string', ['get', 'value']]],
                'text-font': ['Open Sans Bold', 'Arial Unicode MS Bold'],
                'text-size': 12,
                'visibility': 'none'
              },
            });

            FLOORS[floor].slotMachines.forEach(sm => draw.add(sm));
            map.setLayoutProperty('point-circles', 'visibility', 'visible');
            map.setLayoutProperty('point-circles-1', 'visibility', 'visible');
            map.setLayoutProperty('point-labels', 'visibility', 'visible');
          });

        }

        const animate = (hour) => {
          const now = performance.now();
          const timeSpent = now - startTimeRef.current;
          const percentage = timeSpent / duration * 100;
          const data = map.getSource('points').serialize().data;

          if (timeSpent > duration) {
            for (const f of data.features) {
              f.properties.value = f.properties.values[hour] ?? f.properties.values[f.properties.values.length - 1];
            }
            return;
          };

          for (const f of data.features) {
            const prev = f.properties.prev_value;
            const next =  f.properties.values[hour] ?? f.properties.values[f.properties.values.length - 1];
            const range = next - prev;
            const add = range / 100 * percentage;
            const current = Math.round(prev + add);
            f.properties.value = current;
          }

          map.getSource('points').setData(data);

          // request another frame
          requestRef.current = requestAnimationFrame(() => animate(hour));
        }

        const startAnimation = (hour) => {
          cancelAnimationFrame(requestRef.current);
          startTimeRef.current = performance.now();
          animate(hour);
        }

        const updateCircles = hour => {
          const points = map.getSource('points').serialize().data;
          const labels = map.getSource('labels').serialize().data;
          for (const f of points.features) {
            f.properties.prev_value = f.properties.value;
          }
          for (const f of labels.features) {
            f.properties.value = f.properties.values[hour] ?? f.properties.values[f.properties.values.length - 1];
          }
          map.getSource('labels').setData(labels);
          startAnimation(hour);
        }

        map.once('style.load', () => initFloor(floor, hour));

        map.once('load', () => {
          setMap(map);
          initFloorRef.current = initFloor;
          updateCirclesRef.current = updateCircles;
        })

        map.on('draw.modechange', e => {
          const visibility = e.mode === 'simple_select' ? 'visible' : 'none';
          map.setLayoutProperty('point-circles-1', 'visibility', visibility);
          map.setLayoutProperty('point-circles', 'visibility', visibility);
          map.setLayoutProperty('point-labels', 'visibility', visibility);
        });

        map.on('draw.update', e => {
          const features = e.features;
          for (const f of features) {
            const p = FLOORS[floorRef.current].points.features.find(p => p.id === f.id);
            const l = FLOORS[floorRef.current].labels.features.find(l => l.id === f.id);
            if (p) {
              const point = getPointFromCenter(f, p.properties.offset);
              p.geometry.coordinates = point;
              l.geometry.coordinates = point;
            }
          }
          map.getSource('points').setData(FLOORS[floorRef.current].points);
          map.getSource('labels').setData(FLOORS[floorRef.current].labels);
        });

        setFloors(FLOORS);
      } finally {
        setLoading(false);
      }
    })();

    return () => {
      map?.remove();
    }
  }, []); //eslint-disable-line

  return (
    <StyledApp>
      <Contacts />
      <div className="map-wrapper">
        <div id="map" ref={mapRef} />
        <Controls map={map} />
      </div>
      <Floors onChange={onFloorChange} floors={floors} />
      <Legend onChange={onHourChange} />
      {loading && (
        <div className="loader">
          <i className="fas fa-spinner fa-spin"></i>
        </div>
      )}
    </StyledApp>
  );
}

const StyledApp = styled.div`
  position: relative;
  height: 100vh;
  width: 100vw;
  overflow: hidden;
  display: flex;
  background-color: #1D1D1D;

  .loader {
    background-color: #1D1D1D;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1001;
    font-size: 50px;
    color: white;
  }

  .map-wrapper, #map {
    position: relative;
    width: 100%;
    height: 100%;
    flex-grow: 1;
  }

`;

export default App;
