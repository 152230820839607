import { moveFeature, scaleFeature, rotateFeature, getPointFromCenter } from './geometry';
import { featureCollection } from '@turf/helpers';
import getPlanData from '../api/getPlanData';

const copy = object => JSON.parse(JSON.stringify(object));

export const getRandomInt = (min, max) => {
  min = Math.ceil(min);
  max = Math.floor(max);
  return Math.floor(Math.random() * (max - min + 1)) + min;
}

export const generatePlans = async () => {
  const planData = await getPlanData();
  const floors = planData.floors.map(floor => {
    const room = featureCollection(planData.floorPlans.features.filter(f => f.properties.floor_id === floor.id));
    const positions = featureCollection(planData.objectLocations.features.filter(f => f.properties.floor_id === floor.id));
    const objects = transformSlotMachines(positions, planData.objects);
    const slotMachines = objects.filter(f => f.properties.object_id === 1);
    const interriorObjects = featureCollection(objects.filter(f => f.properties.object_id !== 1));
    const points = generatePoints(slotMachines);
    return {
      id: floor.id,
      name: floor.name,
      room: room,
      points: points,
      labels: copy(points),
      slotMachines: slotMachines,
      interriorObjects: interriorObjects,
    }
  });
  return { mapParams: planData.mapParams, floors };
};

const transformSlotMachines = (slotMachinePositions, slotMachines) => (
  slotMachinePositions.features.map(sm => {
    const slotMachine = copy(slotMachines.features.find(s => s.properties.id === sm.properties.object_id) ?? null);
    if (slotMachine) {
      scaleFeature(slotMachine);
      rotateFeature(slotMachine, sm.properties.bearing ?? 0);
      moveFeature(slotMachine, sm.geometry.coordinates);
      return {
        ...slotMachine,
        id: sm.id,
        properties: { 
          ...slotMachine.properties, 
          ...sm.properties,
          centerFeature: sm,
        },
      };
    }
    return null;
  }).filter(Boolean)
);

const generatePoints = (slotMachines) => ({
  type: 'FeatureCollection',
  name: 'points',
  features: slotMachines.map(sm => {
    const offset = { x: 0, y: 0.000008 };
    return {
      id: sm.id,
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: getPointFromCenter(sm, offset),
      },
      properties: {
        values: sm.properties.balance,
        offset,
      }
    }
  }),
});
