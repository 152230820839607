import * as Constants from '@mapbox/mapbox-gl-draw/src/constants';
import doubleClickZoom from '@mapbox/mapbox-gl-draw/src/lib/double_click_zoom';
import * as CommonSelectors from '@mapbox/mapbox-gl-draw/src/lib/common_selectors';
import moveFeatures from '@mapbox/mapbox-gl-draw/src/lib/move_features';

import { point } from '@turf/helpers';
import bearing from '@turf/bearing';
import centerOfMass from '@turf/center-of-mass';
import transformRotate from '@turf/transform-rotate';
import rotateIcon from '../img/rotate.png';

const TxMode = { Move: 1, Rotate: 2 };
const isRotatePoint = CommonSelectors.isOfMetaType(Constants.meta.MIDPOINT);

export const RotateModeStyles = [
  {
    id: 'gl-draw-polygon-fill-inactive',
    type: 'fill',
    filter: [
      'all',
      ['==', 'active', 'false'],
      ['==', '$type', 'Polygon'],
      ['!=', 'user_type', 'overlay'],
      ['!=', 'mode', 'static'],
    ],
    paint: {
      'fill-color': '#D8C392',
      'fill-outline-color': '#D8C392',
      'fill-opacity': 1,
    },
  },
  {
    id: 'gl-draw-polygon-fill-active',
    type: 'fill',
    filter: [
      'all',
      ['==', 'active', 'true'],
      ['==', '$type', 'Polygon'],
      ['!=', 'user_type', 'overlay'],
    ],
    paint: {
      'fill-color': '#A0772F',
      'fill-outline-color': '#A0772F',
      'fill-opacity': 1,
    },
  },
  {
    id: 'gl-draw-polygon-rotate-point-icon',
    type: 'symbol',
    filter: [
      'all',
      ['==', 'meta', 'midpoint'],
      ['==', 'icon', 'rotate'],
      ['==', '$type', 'Point'],
      ['!=', 'mode', 'static'],
    ],
    layout: {
      'icon-image': 'rotate',
      'icon-allow-overlap': true,
      'icon-ignore-placement': true,
      'icon-rotation-alignment': 'map',
      'icon-rotate': ['get', 'heading'],
    },
    paint: {
      'icon-opacity': 1.0,
      'icon-opacity-transition': {
        delay: 0,
        duration: 0,
      },
    },
  },
];

export const RotateMode = {
  onSetup () {
    const featureId = this.getSelected()[0].id;
    const feature = this.getFeature(featureId);
  
    if (!feature) {
      throw new Error('You must provide a valid featureId to enter SRMode');
    }
  
    if (
      feature.type === Constants.geojsonTypes.POINT ||
      feature.type === Constants.geojsonTypes.MULTI_POINT
    ) {
      throw new TypeError('SRMode can not handle points');
    }
  
    const state = {
      featureId,
      feature,
      dragMoveLocation: null,
      dragMoving: false,
      canDragMove: false,
    };
  
    this.setSelected(featureId);
    doubleClickZoom.disable(this);
  
    this.setActionableState({
      combineFeatures: false,
      uncombineFeatures: false,
      trash: true,
    });
  
    if (!this.map.hasImage('rotate')) {
      this.map.loadImage(rotateIcon, (error, image) => {
        if (error) throw error;
        this.map.addImage('rotate', image);
      });
    }
  
    return state;
  },
  toDisplayFeatures(state, geojson, push) {
    if (state.featureId === geojson.properties.id) {
      geojson.properties.active = Constants.activeStates.ACTIVE;
      push(geojson);
      const rotationPoint = this.createRotationPoint(geojson);
      if (rotationPoint) push(rotationPoint);
    } else {
      geojson.properties.active = Constants.activeStates.INACTIVE;
      push(geojson);
    }
  },
  onStop() {
    doubleClickZoom.enable(this);
    this.clearSelectedCoordinates();
  },
  createRotationPoint(geojson) {
    const { type } = geojson.geometry;
    const featureId = geojson.properties && geojson.properties.id;
  
    if (
      type === Constants.geojsonTypes.POINT ||
      type === Constants.geojsonTypes.MULTI_POINT
    ) {
      return;
    }
    
    const cR1 = centerOfMass(geojson).geometry.coordinates;
  
    return {
      type: Constants.geojsonTypes.FEATURE,
      properties: {
        meta: Constants.meta.MIDPOINT,
        icon: 'rotate',
        parent: featureId,
        lng: cR1[0],
        lat: cR1[1],
        heading: 0,
      },
      geometry: {
        type: Constants.geojsonTypes.POINT,
        coordinates: cR1,
      },
    };
  },
  startDragging(state, e) {
    this.map.dragPan.disable();
    state.canDragMove = true;
    state.dragMoveLocation = e.lngLat;
  },
  stopDragging(state) {
    this.map.dragPan.enable();
    state.dragMoving = false;
    state.canDragMove = false;
    state.dragMoveLocation = null;
    state.txMode = TxMode.Move;
  },
  onMouseDown(state, e) {
    if (isRotatePoint(e)) return this.onRotatePoint(state, e);
    if (CommonSelectors.isActiveFeature(e)) return this.onFeature(state, e);
  },
  onTouchStart(state, e) {
    this.onMouseDown(state, e);
  },
  onRotatePoint(state, e) {
    this.computeAxes(state, state.feature.toGeoJSON(), e);
    this.startDragging(state, e);
    const about = e.featureTarget.properties;
    state.selectedCoordPaths = [about.coord_path];
    state.txMode = TxMode.Rotate;
  },
  onFeature(state, e) {
    state.selectedCoordPaths = [];
    this.startDragging(state, e);
  },
  computeAxes(state, feature0, e) {
    const _center = centerOfMass(feature0);
    const m0 = point([e.lngLat.lng, e.lngLat.lat]);
    const heading0 = bearing(_center, m0);
    const heading01 = feature0.properties.bearing;
    const center0 = _center.geometry.coordinates;
    state.rotation = { feature0, center0, heading0, heading01 };
  },
  onDrag(state, e) {
    if (state.canDragMove !== true) return;
    state.dragMoving = true;
    e.originalEvent.stopPropagation();
  
    const delta = {
      lng: e.lngLat.lng - state.dragMoveLocation.lng,
      lat: e.lngLat.lat - state.dragMoveLocation.lat,
    };
    if (state.txMode === TxMode.Rotate) {
      this.dragRotatePoint(state, e, delta);
    } else {
      this.dragMoveFeature(state, e, delta);
    }
  
    state.dragMoveLocation = e.lngLat;
  },
  dragRotatePoint(state, e) {
    if (!state.rotation) {
      throw new Error('state.rotation required');
    }
  
    const m1 = point([e.lngLat.lng, e.lngLat.lat]);
    const center = point(state.rotation.center0);
    const heading1 = bearing(center, m1);
    const rotateAngle = heading1 - state.rotation.heading0;

    const rotatedFeature = transformRotate(state.rotation.feature0, rotateAngle, {
      pivot: center,
      mutate: false,
    });

    const centerFeature = state.feature.properties.centerFeature;
    const featureBearing = rotateAngle + state.rotation.heading01;
    centerFeature.properties.bearing = featureBearing;
    state.feature.ctx.api.setFeatureProperty(state.feature.id, 'bearing', featureBearing);
    state.feature.ctx.api.setFeatureProperty(state.feature.id, 'centerFeature', centerFeature);
    state.feature.incomingCoords(rotatedFeature.geometry.coordinates);
    this.fireUpdate();
  },
  dragMoveFeature(state, e, delta) {
    moveFeatures(this.getSelected(), delta);
    state.dragMoveLocation = e.lngLat;
    const centerFeature = state.feature.properties.centerFeature;
    const feature = state.feature.toGeoJSON();
    const center = centerOfMass(feature);
    centerFeature.geometry = center.geometry;
    state.feature.ctx.api.setFeatureProperty(state.feature.id, 'centerFeature', centerFeature);
    this.fireUpdate();
  },
  fireUpdate() {
    this.map.fire(Constants.events.UPDATE, {
      action: Constants.updateActions.CHANGE_COORDINATES,
      features: this.getSelected().map((f) =>f.toGeoJSON()),
    });
  },
  onMouseOut(state) {
    if (state.dragMoving) this.fireUpdate();
  },
  onMouseUp(state) {
    if (state.dragMoving) this.fireUpdate();
    this.stopDragging(state);
  },
  onTouchEnd(state) {
    this.onMouseUp(state);
  },
  onClick(state, e) {
    if (CommonSelectors.noTarget(e)) return this.clickNoTarget(state, e);
    if (CommonSelectors.isInactiveFeature(e)) return this.clickInactive(state, e);
    this.stopDragging(state);
  },
  clickNoTarget() {
    this.changeMode(Constants.modes.SIMPLE_SELECT);
  },
  clickInactive(_, e) {
    this.setSelected(e.featureTarget.properties.id);
    return this.changeMode('rotate');
  },
  onTrash() {
    this.deleteFeature(this.getSelectedIds());
  }
};
