import React from 'react';
import moment from 'moment';
import styled from 'styled-components';
import Slider from './Slider';

const Legend = ({ onChange }) => {
  return (
    <StyledLegend>
      <div className="title">Color legend (values $):</div>
      <div className="color"></div>
      <div className="values">
        <span>1 000</span>
        <span>200 000</span>
      </div>
      <div className="slider-container">
        <div className="date">
          <div className="arrow"><i className="fa-solid fa-chevron-left"></i></div>
          <div className="text">{moment().format('D MMMM YYYY')}</div>
          <div className="arrow"><i className="fa-solid fa-chevron-right"></i></div>
        </div>
        <Slider onChange={onChange}/>
      </div>
    </StyledLegend>
  );
}

const StyledLegend = styled.div`
  position: absolute;
  right: 30px;
  top: 30px;
  border-radius: 10px;
  padding: 30px;
  border: 1px solid #d8c3927f;
  background: rgba(28, 25, 22, 0.7);
  backdrop-filter: blur(7.5px);

  .title {
    font-weight: 500;
    font-size: 15px;
    color: #FFFFFF;
    margin-bottom: 15px;
  }

  .color {
    min-width: 300px;
    width: 100%;
    height: 10px;
    background: linear-gradient(90deg, #D8C392 0%, #B7591A 100%);
    border-radius: 2px;
    margin-bottom: 5px;
  }

  .values {
    display: flex;
    justify-content: space-between;
    font-weight: 500;
    font-size: 12px;
    color: #8B8988;
  }

  .slider-container {
    background: rgba(39, 37, 34, 0.3);
    border-radius: 10px ;
    padding: 15px;
    margin-top: 20px;

    .date {
      display: flex;
      justify-content: center;
      align-items: center;
      font-weight: 500;
      font-size: 14px;
      margin-bottom: 10px;

      .arrow {
        color: #D8C392;
        cursor: pointer;

        &:hover {
          color: #a18441;
        }
      }

      .text {
        color: #8B8988;
        margin: 0 10px;
      }

    }

  }
`;

export default Legend;
