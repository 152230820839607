import React, { useEffect, useState } from 'react';
import styled from 'styled-components';

const Floors = ({ onChange, floors }) => {
  const [value, setValue] = useState(0);

  useEffect(() => {
    onChange?.(value);
  }, [value]); // eslint-disable-line

  return (
    <StyledFloors>
      <div className="title">Please, choose a floor</div>
      <div className="floors">
        {floors?.map((_, i) => (
          <div key={i} className={`floor ${value === i ? 'active' : ''}`} onClick={() => setValue(i)}>
            <div className="description">{i + 1}</div>
          </div>
        ))}
      </div>
    </StyledFloors>
  );
}

const StyledFloors = styled.div`
  position: absolute;
  bottom: 30px;
  left: 50%;
  border-radius: 10px;
  transform: translateX(-50%);
  padding: 15px;
  margin: 0 !important;
  border: 1px solid #d8c3927f;
  background: rgba(28, 25, 22, 0.7);
  backdrop-filter: blur(7.5px);
  display: flex;
  flex-direction: column;

  .title {
    font-style: normal;
    font-weight: 500;
    font-size: 15px;
    color: #FFFFFF;
    text-align: center;
    margin-bottom: 15px;
    white-space: nowrap;
  }

  .floors {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .floor {
    border: 1px solid #d8c3927f;
    border-radius: 50%;
    width: 40px;
    height: 40px;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;

    .description {
      font-weight: 500;
      font-size: 15px;
      color: #FFFFFF;
      text-align: center;
    }

    .image {
      display: flex;
      justify-content: center;
      align-items: center;

      img {
        width: 150px;
        object-fit: contain;
      }
    }

    &:hover, &.active {
      border: 1px solid #D8C392;
    }

    &.active {
      background: rgba(216, 195, 146, 0.10);
    }

    &:hover {
      background: rgba(216, 195, 146, 0.15);
    }

    &:not(:last-child) {
      margin-right: 10px;
    }
  }

`;

export default Floors;
