import { point, featureCollection } from '@turf/helpers';
import centerOfMass from '@turf/center-of-mass';
import centroid from '@turf/centroid';
import transformRotate from '@turf/transform-rotate';
import transformTranslate from '@turf/transform-translate';
import transformScale from '@turf/transform-scale';
import rhumbBearing from '@turf/rhumb-bearing';
import rhumbDistance from '@turf/rhumb-distance';

export const polygonToPointCollection = (collection) => {
  return featureCollection(collection.features.map(f => ({ ...f, geometry: centerOfMass(f).geometry })))
}

export const getPointFromCenter = (feature, offset) => {
  const center = centerOfMass(feature);
  const [centerX, centerY] = center.geometry.coordinates;
  const pointPosition = point([centerX + offset.x, centerY + offset.y])
  transformRotate(pointPosition, feature.properties.bearing ?? 0, { pivot: center, mutate: true });
  return pointPosition.geometry.coordinates;
}

export const moveFeature = (feature, moveTo) => {
  const pointToMove = point(moveTo);
  const center = point(feature.properties.geom_centroid.coordinates) ?? centroid(feature);
  const distanceToPoint = rhumbDistance(center, pointToMove);
  const bearingToPoint = rhumbBearing(center, pointToMove);
  const movedFeature = transformTranslate(feature, distanceToPoint, bearingToPoint);
  feature.geometry.coordinates = movedFeature.geometry.coordinates;
  return feature;
}

export const scaleFeature = (feature) => {
  if (feature.properties.scale) {
    const center = point(feature.properties.geom_centroid.coordinates) ?? centroid(feature);
    const scaledFeature = transformScale(feature, feature.properties.scale, { origin: center });
    feature.geometry.coordinates = scaledFeature.geometry.coordinates;
  }
  return feature;
}

export const rotateFeature = (feature, angle) => {
  const center = point(feature.properties.geom_centroid.coordinates) ?? centroid(feature);
  const rotatedFeature = transformRotate(feature, angle, { pivot: center });
  feature.geometry.coordinates = rotatedFeature.geometry.coordinates;
  return feature;
}
