import React, { useState, useEffect, useMemo, useRef } from 'react';
import styled from 'styled-components';

const Slider = ({ onChange }) => {
  const [value, setValue] = useState(0);
  const [isRunning, setRunning] = useState(false);
  const progress = useMemo(() => (value  / 23  * 100) + '%', [value]);
  const timer = useRef(null);

  useEffect(() => {
    onChange?.(value);
  }, [value]); // eslint-disable-line

  useEffect(() => {
    if (!isRunning) return;
    timer.current = setInterval(() => {
      setValue(v => {
        if (v < 23) return v + 1;
        clearInterval(timer.current);
        setRunning(false);
        return 0;
      })
    }, 1000);
    return () => {
      clearInterval(timer.current);
    }
  }, [isRunning]);

  return (
    <StyledSlider>
      <div className="play" onClick={() => setRunning(state => !state)}>
        <i className={`fa-solid fa-${isRunning ? 'pause' : 'play'}`}></i>
      </div>
      <div className="track">
        <div className="progress" style={{ width: progress }}></div>
        {[...Array(22).keys()].map(v => ++v).map(v => (
          <div key={v} className="tick" style={{ left: (v  / 23  * 100) + '%' }}></div>
        ))}
        <div className="thumb" style={{ left: progress }}></div>
        <div className="time">
          <span>12:00 AM</span>
          <span>11:00 PM</span>
        </div>
        <input type="range" min="0" max="23" step="1" value={value} onChange={e => setValue(+e.target.value)} />
      </div>
    </StyledSlider>
  );
}

const StyledSlider = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 15px;

  .play {
    color: #D8C392;
    margin-right: 10px;
    cursor: pointer;
    width: 14px;

    &:hover {
      color: #a18441;
    }
  }

  .track {
    width: 100%;
    background-color: #8B8988;
    height: 4px;
    border-radius: 2px;
    position: relative;

    input {
      position: absolute;
      top: 0;
      left: -5px;
      height: 100%;
      width: calc(100% + 5px);
      opacity: 0;
    }

    .progress {
      background: #D8C392;
      border-radius: 2px;
      position: absolute;
      left: 0;
      top: 0;
      height: 100%;
      width: 0%;
    }

    .tick {
      position: absolute;
      left: 0;
      top: 0;
      height: 100%;
      width: 2px;
      background: #1C1916;
      transform: translateX(-50%);
    }

    .thumb {
      position: absolute;
      left: 0%;
      top: 50%;
      transform: translate(-50%, -50%);
      height: 14px;
      width: 10px;
      background: #D8C392;
      border-radius: 2px;
    }

    .time {
      position: absolute;
      width: 100%;
      top: 100%;
      left: 0;
      transform: translateY(10px);
      display: flex;
      justify-content: space-between;
      font-weight: 500;
      font-size: 12px;
      color: #8B8988;
    }

  }

`;

export default Slider;
