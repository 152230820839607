const getPlanData = async () => {
  const endpoints = [
    'https://indoor-map.mappitall.com:3023/get_layer_params/data__casino_2',
    'https://indoor-map.mappitall.com:3023/get_floors/data__casino_2',
    'https://indoor-map.mappitall.com:3023/get_plan/data__casino_2',
    'https://indoor-map.mappitall.com:3023/get_objects/data__casino_2',
    'https://indoor-map.mappitall.com:3023/get_object_location/data__casino_2',
  ].map(url => fetch(url).then(response => response.json()));
  const [mapParams, floors, floorPlans, objects, objectLocations] = await Promise.all(endpoints);
  return { mapParams, floors, floorPlans, objects, objectLocations };
};

export default getPlanData;
