import React from 'react';
import styled from 'styled-components';
import PlusIcon from '../img/plus.svg';
import MinusIcon from '../img/minus.svg';

const Controls = ({ map }) => {
  return (
    <StyledControls>
      <div className="button" onClick={() => map?.zoomIn()}>
        <img src={PlusIcon} alt="zoom-in" draggable="false"/>
      </div>
      <div className="button" onClick={() => map?.zoomOut()}>
        <img src={MinusIcon} alt="zoom-out" draggable="false"/>
      </div>
    </StyledControls>
  );
}

const StyledControls = styled.div`
  position: absolute;
  right: 30px;
  bottom: 30px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  user-select: none;

  .button {
    background-color: #1C1916;
    border: 1px solid #d8c3927f;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 10px;
    cursor: pointer;

    img {
      object-fit: contain;
      width: 15px;
      height: 15px;
    }

    &:hover {
      background-color: #2a241f;
    }

    &:not(:last-child) {
      margin-bottom: 10px;
    }
  }

`;

export default Controls;
